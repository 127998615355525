
import { type Either, right, left } from "fp-ts/Either"

import HttpClient from "~/lib/core/http/http_client";
import { API_Endpoints } from "~/lib/core/configs/api_endpoints";
import { AppFailure, Failure, ServerFailure } from "~/lib/core/error/failure";
import { UserInfo } from "../models/user_info_model";
import { CustomStorage } from '~/lib/core/utils/custom_storage'
import type { PublicCfg } from "~/lib/core/plugins/services";


export abstract class AuthRepository {
    abstract logout(): Promise<Either<Failure, boolean>>
    abstract login(username: string, password: string): Promise<Either<Failure, UserInfo>>
    abstract register(data: any): Promise<Either<Failure, string>>
    abstract confirmRegistration(data: any): Promise<Either<Failure, string>>
    abstract resendConfirmKey(data: any): Promise<Either<Failure, string>>
    abstract requestResetPassword(data: any): Promise<Either<Failure, string>>
    abstract submitResetPassword(data: any): Promise<Either<Failure, string>>
    abstract storeUser(userInfo: UserInfo): Promise<Either<Failure, boolean>> 
    abstract loadUser(): Promise<Either<Failure, UserInfo>> 
    abstract fetchUserInfo(): Promise<Either<Failure, UserInfo>> 
    abstract updateUserInfo(userInfo: UserInfo): Promise<Either<Failure, UserInfo>> 
}


export class AuthRepositoryImpl implements AuthRepository {

    constructor(private _httpClient: HttpClient, public publicCfg: PublicCfg) { };

    async logout(): Promise<Either<Failure, boolean>> {
        CustomStorage.write("user-info")
        return right(true)
    }


    async login(email: string, password: string): Promise<Either<Failure, UserInfo>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.authLogin],
                    body: { email, password }
                }
            )

            const frappeResponse = response.data.message

            if (frappeResponse.status == 200) {
                const userModel = (frappeResponse.data as UserInfo)
                const userInfo = UserInfo.fromJson(userModel);
                console.log("convert res: userInfo ", userInfo);

                return right(userInfo as UserInfo)
            } else {
                return left(new ServerFailure(frappeResponse.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }

    async register(data: any): Promise<Either<Failure, string>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.authRegister],
                    body: { ...data }
                }
            )

            const frappeResponse = response.data.message

            if (frappeResponse.status == 200) {
                return right(frappeResponse.info)
            } else {
                return left(new ServerFailure(frappeResponse.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }

    async confirmRegistration(data: any): Promise<Either<Failure, string>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.authConfirmRegistration],
                    body: { ...data }
                }
            )

            const frappeResponse = response.data.message

            if (frappeResponse.status == 200) {
                return right(frappeResponse.info)
            } else {
                return left(new ServerFailure(frappeResponse.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }

    async resendConfirmKey(data: any): Promise<Either<Failure, string>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.authRequestConfirmKey],
                    body: { ...data }
                }
            )

            const frappeResponse = response.data.message

            if (frappeResponse.status == 200) {
                return right(frappeResponse.info)
            } else {
                return left(new ServerFailure(frappeResponse.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }
    
    async requestResetPassword(data: any): Promise<Either<Failure, string>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.authRequestResetPassword],
                    body: { ...data }
                }
            )

            const frappeResponse = response.data.message

            if (frappeResponse.status == 200) {
                return right(frappeResponse.info)
            } else {
                return left(new ServerFailure(frappeResponse.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }

    async submitResetPassword(data: any): Promise<Either<Failure, string>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.authSubmitResetPassword],
                    body: { ...data }
                }
            )

            const frappeResponse = response.data.message

            if (frappeResponse.status == 200) {
                return right(frappeResponse.info)
            } else {
                return left(new ServerFailure(frappeResponse.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }

    async storeUser(userInfo: UserInfo): Promise<Either<Failure, boolean>> {
        try {
            const jsonUser = UserInfo.toJson(userInfo)
            CustomStorage.write("user-info", jsonUser)
            return right(true)

        } catch (e) {
            return left(new AppFailure(String(e)));
        }
    }

    async loadUser(): Promise<Either<Failure, UserInfo>> {
        try {
            const userJson = CustomStorage.read("user-info")
            
            if(userJson) {
                const userInfo = UserInfo.fromJson(userJson)
                return right(userInfo)
            } else {
                return left(new AppFailure("no_stored_user_data"));
            }
            

        } catch (e) {
            return left(new AppFailure(String(e)));
        }
    }

    async fetchUserInfo(): Promise<Either<Failure, UserInfo>> {
        try {
            const response = await this._httpClient.get<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.authFetchUserInfo],
                    body: { },
                    withAuth: true
                }
            )

            const frappeResponse = response.data.message

            if (frappeResponse.status == 200) {
                const userModel = (frappeResponse.data as UserInfo)
                const userInfo = UserInfo.fromJson(userModel);
                console.log("convert res: userInfo ", userInfo);
                

                return right(userInfo as UserInfo)
            } else {
                return left(new ServerFailure(frappeResponse.reason))
            }
        } catch (e) {
            if (e instanceof ServerFailure) {
                return left(e);
            }
            return left(new AppFailure(String(e)));
        }
    }

    async updateUserInfo(userInfo: UserInfo): Promise<Either<Failure, UserInfo>> {
        try {
            const response = await this._httpClient.post<any>(
                {
                    paths: [this.publicCfg.baseApi, API_Endpoints.authUpdateUserInfo],
                    body: { ...userInfo },
                    withAuth: true
                }
            )

            const frappeResponse = response.data.message

            if (frappeResponse.status == 200) {
                const userInfoModel = (frappeResponse.data as UserInfo)
                const userInfo = UserInfo.fromJson(userInfoModel);
                
                return right(userInfo as UserInfo)
            } else {
                return left(new ServerFailure(frappeResponse.reason))
            }

        } catch (e) {
            return left(new AppFailure(String(e)));
        }
    }
}